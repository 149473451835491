import Social from '../Assets/img/whatsapp.gif';

const SocialButton = () => {
  return (
    <div role="button" className="fixed bottom-20 left-7 w-14 xs:hidden sm:block">
      <a href={process.env.REACT_APP_WA} target="_blank" rel="nofollow noreferrer">
        <img className="h-full w-full" src={Social} alt="Social Media" />
      </a>
    </div>
  );
}

export default SocialButton;
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Content from '../Content/Content';

export default function Items({ currentItems }) {
  return (
    <>
      <Content currentItems={currentItems}>
      </Content>
    </>
  );
}

export function PaginatedItems({ itemsPerPage, searchParam }) {
  const urlPath = process.env.REACT_APP_URL;
  const [currentItems, setCurrentItems] = useState([]);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const url = searchParam ? urlPath + (searchParam) : urlPath;
  const getData = async () => {
    try {
      const res = await fetch(url);
      const dataRes = await res.json();
      setItems(dataRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [searchParam])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [items, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>

      <Items currentItems={currentItems} />
      <ReactPaginate
        className='flex flex-row align-middle text-center justify-center bg-[#000000] font-bold text-white py-5'
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item flex mx-1 border-2 rounded-2xl py-1 px-2 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500"
        pageLinkClassName="page-link inline-flex"
        previousClassName="page-item mx-1 border-2 rounded-2xl py-1 px-2 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500"
        previousLinkClassName="page-link"
        nextClassName="page-item mx-1 border-2 rounded-2xl py-1 px-2 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item mx-1 align-middle "
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active bg-gradient-to-b from-gray-800 to-gray-500"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
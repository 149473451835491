import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './Header/Header.jsx';
import Footer from './Footer/Footer.jsx';

import {PaginatedItems} from './Addition_Content/Pagination.jsx'

function App() {

  useEffect(() => {
    const iconPath = process.env.REACT_APP_ICON;
    document.title = process.env.REACT_APP_TITLE;
    const favicon = document.getElementById('favicon');
    const faviconUrl = process.env.PUBLIC_URL + iconPath;
    favicon.href = faviconUrl;
    favicon.type = 'image/png';
  }, []);

  const [searchParam, setSearchParam] = useState(null);
  
  return (
    <div className="App h-full">
      <Header setSearchParam = {setSearchParam}></Header>
      <PaginatedItems itemsPerPage={50} searchParam={searchParam} ></PaginatedItems>
      <Footer></Footer>
    </div>
  );
}

export default App;

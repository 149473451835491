// svg & logo
import home from "../Assets/svg/home.svg";
import chat from "../Assets/svg/chat.svg";
import discount from "../Assets/svg/discount.svg";
import people from "../Assets/svg/people.svg";


export default function Header({ setSearchParam }) {
  const logoPath = process.env.REACT_APP_LOGO;

  function search(e) {
    setSearchParam(e.target.value);
  }
  return (
    <nav className="bg-gradient-to-b from-red-900 to-black">
      <div className="mx-auto max-w-7xl px-2 sm:px-3 lg:px-8 relative flex h-16 items-center justify-between bg-gradient-to-b from-red-900 to-black">
        <div className="flex items-center font-digital lg:px-0">
          <div className="flex-shrink-0">
            <a href={process.env.REACT_APP_HOME} target="_blank" rel="nofollow noreferrer">
              <img className="h-8 w-auto" src={process.env.PUBLIC_URL + logoPath} alt={process.env.REACT_APP_NAME} />
            </a>
          </div>
          <div className="hidden lg:ml-6 md:block">
            <div className="flex">
              <a href={process.env.REACT_APP_HOME} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 justify-between text-sm font-medium text-gray-300 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500" >
                <img className="h-8 w-auto mx-auto" src={home} alt="Login" />
                <span>Login</span>
              </a>
              <a href={process.env.REACT_APP_DAFTAR} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 justify-between text-sm font-medium text-gray-300 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500">
                <img className="h-8 w-auto mx-auto" src={people} alt="Daftar" />
                <span>Daftar</span>
              </a>
              <a href={process.env.REACT_APP_PROMO} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 justify-between text-sm font-medium text-gray-300 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500">
                <img className="h-8 w-auto mx-auto" src={discount} alt="Promo" />
                <span>Promo</span>
              </a>
              <a href={process.env.REACT_APP_LIVECHAT} target="_blank" rel="nofollow noreferrer" className="rounded-md px-3 py-3 place-items-center text-sm font-medium text-gray-300 bg-gradient-to-b hover:from-gray-800 hover:to-gray-500">
                <img className="h-8 w-auto mx-auto" src={chat} alt="Live Chat" />
                <span>Live Chat</span>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
          <div className="w-full max-w-lg lg:max-w-xs">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                </svg>
              </div>
              <input id="search" name="search" onChange={search} className="block w-full rounded-md border-0 bg-gray-900 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}